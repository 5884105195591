.post-list_container {
}

.tag-cards_container{
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  right: 25px;
}
.container_postform {
  margin-top: 20px;
}

.content-container {
  min-height: 300px;
}

.ql-container {
  display: block;
  min-height: 150px;
  margin-bottom: 100px;
}

.ql-editor p {
  font-size: 16px;
  line-height: 1.5;
}
.welcome-title {
  color: #333;
  font-weight: bold;
}

.input-group-append .btn {
  border-radius: 0 4px 4px 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #dde1e7 !important;
}

.post-card {
  cursor: pointer;
  transition: transform 0.2s;
  width: 140px;
  max-height: 180px;
  min-height: 180px;
  overflow: hidden;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.post-list_header {
  color: #888;
  text-shadow: #ffffff 1px 1px 1px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.container_books-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.h6_title-lastposts {
  top: 20px;
}

.post-card:hover {
  animation: shake 0.5s;
  animation-iteration-count: 0.4;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  text-align: center;
  overflow: hidden;
  padding: 14px;
}

.card-subtitle {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.card-text {
  display:none}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-img-top {
  max-height: 300px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  transition: transform 0.2s;
}

.modal-img {
  display: block;
  width: 50%;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 1rem;
}

.edit-del_btn {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 2px;
}

.btn-primary,
.btn-danger {
  font-size: 0.8rem !important;
  margin: 5px;
  font-size: 0.5rem;
}

.date-text {
  color: #666;
  font-size: 0.8rem;
}

.card.post-card {
  cursor: pointer;
  transition: transform 0.2s;
  background: #dde1e7 !important;
  border-radius: 20px !important;
  margin: 20px !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
}

.card-search {
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563);
}

.btn-search {
  background: #dde1e7 !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
}
.list-group {
  background: #dde1e7 !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563), inset 0 0 0 2px #ccc !important;
  border-radius: 20px !important;
  margin: 20px !important;
  text-align: center;
  padding: 10px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  align-items: center !important;
  font-family: "vazir" !important;
}

.list-group-item {
  max-width: fit-content !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  font-family: "vazir" !important;
  color: #666 !important;
  border-radius: 20px !important;
  background: #dde1e7 !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  margin: 5px !important;
  min-width: 90%;
  text-align: center;
  direction: rtl;
}

.modal-content {
  width: 80%;
}

.modal-body {
  margin: 50px;
  line-height: 1.5;
}

.modal-dialog.custom-modal-size {
  max-width: 80%;
  margin: 5rem auto;
}

.modal-content {
  width: 100%;
}

@media (max-width: 768px) {
  .modal-dialog.custom-modal-size {
    width: 90%;
    max-width: 90%;
    font-size: 1rem;
  }

  .modal-body {
    padding: 0.1rem;
    margin: 1px;
    line-height: 1.5rem;
  }
  .list-group {
    display: none !important;
  }
  .card-title {
    font-size: 0.7rem;
    top: 10px;
    overflow: hidden;
  }

}

@media (max-width: 768px) {
  .modal-dialog.custom-modal-size {
    width: 90%;
    max-width: 90%;
    font-size: 1rem;
  }

  .modal-body {
    padding: 0.1rem;
    margin: 1px;
    line-height: 1.5rem;
  }
  .list-group {
    display: none !important;
  }
  .card-title {
    font-size: 0.7rem;
    top: 10px;
    overflow: hidden;
  }
  .tag-cards_{
    right:10px
  }
}

@media (max-width: 768px) and (min-width: 600px) {
  .tag-cards_{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  }
}