.post-detail{
  margin-bottom: 0px;
  padding: 10px;
  text-align: justify;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.post-content {
  line-height: 1.8;
}

.det-container{
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  font-family: 'vazir';
  font-size: 1rem;
  font-weight: 300;
  color: #333;
  line-height: 1.5;
  text-align: justify;
background-color: #dde1e7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  direction: rtl;
}

.det-titel{
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 30px 0;
}

.author-info {
  font-size: 0.9rem;
}

.det-img{
  position: relative;
  width: 20% auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.share-btn{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: .8rem;
}
.share-btn i{
font-size: 1.5rem;
}

.share-btn:hover{
  color: #23b4be;
}

.date_ {
  font-family: 'Arial', sans-serif;
}