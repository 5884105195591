.container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.second_container {
  position: relative;
  width: 100%;
  justify-content: center;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  text-align: right;
}

.about-container h2 {
  position: relative;
  font-size: 20px;
  bottom: 20px;
  right: 0;
}

.image-lyers {
  position: relative;
}

.author-container {
  position: absolute;
  scale: 1;
  left: -200px;
  top: 2rem;
}

.author-picture {
  position: absolute;
  max-width: 10rem;
  max-height: 10rem;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  border: 5px solid #fff;
  animation: pulse 6s infinite;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  z-index: 0;
}
.about_auther {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.books-menu {
  display: flex;
  justify-content: center;
  scale: 0.7;
  margin-top: 15px;
}

.books-menu a {
  margin: 0 200px;
}

.carousel-inner {
  position: relative;
  top: -29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card-body_a {
  border-radius: 20px !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  padding-right: 80px;
  padding-left: 80px;
}

.card-body_b {
  position: relative;
  top: 10px;
  width: 300px;
  min-height: 100px;
  border-radius: 20px !important;
  margin: 20px !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #23b4be;
  border-radius: 12%;
  font-size: 20px;
  height: 40px;
  width: 40px;
  z-index: 1;
}

.card {
  background-color: transparent;
  border: none;
}

.card_a {
  left: -167px;
}

.carousel_width {
  width: 70%;
  left: 15%;
  right: 15%;
  margin: auto 0;
  border: none;
  position: relative;
  border-radius: 20px !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  background: #dde1e7;
  z-index: 1;
}

.pre-type {
  font-size: 0.8rem;
}

@media (max-width: 993px) {
  .carousel_width {
    width: 100%;
    left: 0;
    right: 0;
  }

  .card-body_a {
    padding-right: 66px;
    padding-left: 66px;
  }
}

.drop {
  position: relative;
  margin: 0;
  height: 150px;
  width: 150px;
  background: #54abfb;
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  opacity: 0.8;
  border: 2px solid #3d93ff;
  animation: wobble 1s linear infinite alternate;
  z-index: 1;
  opacity: 0.3;
  scale: 1.3;
}

.drop::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #318cfe;
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  box-shadow: -20px 30px 16px #1b6cfb, -40px 60px 32px #1b6cfb,
    inset -6px 6px 10px #1b6cfb, inset 2px 6px 10px #1a74e5,
    inset 20px -20px 22px white, inset 40px -40px 44px #a8ceff;
  animation: wobble 3s linear infinite alternate;
}

.drop::after {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  background: #e6fdfb;
  border-radius: 44% 56% 46% 54% / 36% 50% 50% 64%;
  left: 100px;
  top: 40px;
  box-shadow: 16px 40px 0 -10px white;
  opacity: 0.8;
  animation: shine 2s linear infinite alternate;
}

.drop_2 {
  position: absolute;
  left: 85px;
  top: 8rem;
  margin: 0;
  height: 100px;
  width: 135px;
  background: #54abfb;
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  opacity: 0.8;
  border: 2px solid #3d93ff;
  animation: wobble 1s linear infinite alternate;
  z-index: 1;
  opacity: 0.3;
  scale: 0.25;
}

.drop_2::before {
  content: "";
  position: absolute;
  height: 120%;
  width: 100%;
  background: #318cfe;
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  box-shadow: -20px 30px 16px #1b6cfb, -40px 60px 32px #1b6cfb,
    inset -6px 6px 10px #1b6cfb, inset 2px 6px 10px #1a74e5,
    inset 20px -20px 52px white, inset 40px -40px 44px #a8ceff;
  animation: wobble 3s linear infinite alternate;
}

.drop_2::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background: #ffffff;
  border-radius: 44% 56% 46% 54% / 36% 50% 50% 64%;
  left: -40px;
  top: -40px;
  box-shadow: 16px 20px 0 -10px white;
  opacity: 1;
  animation: shine 2s linear infinite alternate;
}

@keyframes wobble {
  0% {
    border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  }
  50% {
    border-radius: 46% 53% 42% 59% / 58% 48% 50% 43%;
  }
  100% {
    border-radius: 41% 57% 38% 65% / 54% 58% 46% 48%;
  }
}

@keyframes shine {
  0% {
    left: 80px;
    top: 40px;
  }
  50% {
    left: 85px;
    top: 44px;
  }
  100% {
    left: 74px;
    top: 38px;
  }
}

.read_it-btn {
  background-color: #23b4be;
  color: white;
  border: none;
  width: 100px;
  height: 40px;
  transition: all 0.1s ease-in-out;
}

.read_it-btn:hover {
  background-color: #23b4be;
  color: white;
}

/* Text Styles */
.pre-type {
  max-width: 90%;
  font-family: "Vazir", sans-serif;
  line-height: 1.8;
  color: #343a40;
  direction: rtl;
  font-weight: 500;
  text-align: justify;
}

.h6_title {
  position: relative;
  padding: 10px;
  font-family: "Vazir", sans-serif;
  font-weight: 700;
  color: #495057;
}
.h6_title i {
  position: relative;
  font-size: 1.5rem;
  top: -10px;
}
.h6_title:hover {
}

.card-title_last {
  font-family: "Vazir", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #343a40;
  margin-bottom: 15px;
  min-width: 150px;
  overflow: hidden;
}

.container_books-menu h6 {
  position: relative;
  font-family: "Vazir", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #343a40;
  top: 20px;
  min-width: max-content;
  overflow: hidden;
}

.manual-posts {
  position: fixed;
  display: block;
  width: 200px;
  height: max-content;
  right: 100px;
  top: 15rem;
  border-radius: 20px !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  padding: 10px;
}

.manual-post_ul {
  position: relative;
  width: 100%;
  direction: rtl;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.manual-post_ul h6 {
  position: relative;
  padding: 20px;
  width: 100%;
  background-color: #23b4be;
  color: #fff;
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
}

.manual-post_li {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #343a40;
  text-decoration: none;
  text-decoration: none !important;
  font-size: 0.8rem;
  letter-spacing: -0.04rem;
}

.manual-post_li:hover {
  background-color: #e9ecef;
}

.manual-post_li a {
  position: relative;
  width: 100%;
  height: 100%;
  color: #343a40;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
  font-size: 0.8rem;
  letter-spacing: -0.04rem;
}

.manual-post_li a:hover {
  text-decoration: underline;
}

@media (max-width: 1300px) {
  .about_auther {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .about_auther h2 {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: 700;
    color: #343a40;
    text-align: center;
    margin-top: 100px;
  }

  .author-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
  }
  .manual-posts {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 0;
    top: 0;
  }
}
