.navbar {
  display: flex;
  color: #666 !important;
  border-radius: 20px;
  background: #dde1e7 !important;
  box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.762),
    5px 5px 9px rgba(94, 104, 121, 0.563) !important;
  min-width: 85%;
  margin: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  font-size: 1.1rem;
}
/* .logo{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
} */

.logo{
  font-family: "vazir";
}
.navbar-brand {
  font-size: 1.3rem;
  font-weight: 700;
  color: #343a40 !important;
}

.nav-link {
  color: #343a40 !important;
}

.nav-link:hover {
  color: #007bff !important;
}

.search-container {
  position: relative;
}

.search-results {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
}

.list-group_search{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 4px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}
.search-results .list-group-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  max-width: 100%;
  max-height: 30px;
  overflow: hidden;
}

.search-results .list-group-item:hover {
  background-color: #f8f9fa;
}

.list-group-item a {
  cursor: pointer;
  text-decoration: none;
  color: #343a40;
  font-size: .8rem;
  font-family: "Vazir", sans-serif;
  overflow: hidden;
}

.search-icon{
  position: absolute;
  top: 50%;
  right: 115px;
  transform: translateY(-60%);
  font-size: 1.2rem;
  color: #343a40;
  cursor: pointer;
}

@media (max-width: 993px) {
  .navbar {
    width: 100%;
    padding-left: 25px;
    padding-right: 20px;
    border-radius: 0;
    margin-top: 0;
  }
  .search-icon{
    display: none;
  }
}
