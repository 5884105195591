html {
  scroll-behavior: smooth;
  font-family: "vazir" sans-serif;
}

body {
  
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body[dir="rtl"] {
  font-family: "vazir";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3 {
  word-spacing: -0.1rem;
}
