.App {
  position: relative;
  background-image:url('https://images.unsplash.com/photo-1638132035918-90a22beaab3b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: 97dvh;
}

.developer-link {
  font-size: 0.8rem;
  min-width: 0px;
  position: absolute;
  font-size:13px;
  right: 5px;
  bottom: 5px;
  color: #34bdfc;
  text-decoration: none;
  z-index: 1;
}
